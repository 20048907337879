export default {
    currEnv: 'uat',
    fbLink: 'https://www.facebook.com/ELog-101027588299498/?ref=page_internal',
    instagramLink: 'https://www.instagram.com/elog_ph/',
    elogLink: 'http://linkedin.com/company/elogph',
    supportEmail: 'support@tawitech.ph',
    elogPlayStoreLink: 'https://play.google.com/store/apps/details?id=com.tawitech.elog.main',
    hubGuardPlayStoreLink: 'https://play.google.com/store/apps/details?id=com.tawitech.elog.hubguard',
    elogOneDriveLink: 'https://onedrive.live.com/?cid=d381a3146e91efc1&id=D381A3146E91EFC1%21105&authkey=!AC7mar9Uo7YvuGY',
    hubGuardOneDriveLink: 'https://onedrive.live.com/?authkey=%21AJhTYPbqbfm67Mg&id=D381A3146E91EFC1%21109&cid=D381A3146E91EFC1',
    elogFBStorageLink: 'https://firebasestorage.googleapis.com/v0/b/elog-app.appspot.com/o/apks%2Felog-main-v1.8.27-LATEST.apk?alt=media&token=9942dd6c-9851-40be-b550-01b3c682936c',
    hubGuardFBStorageLink: 'https://firebasestorage.googleapis.com/v0/b/elog-app.appspot.com/o/apks%2Felog-hubguard-v1.8.27-LATEST-sdk26.apk?alt=media&token=40ae64d1-a2e9-458a-b0d9-24d7f598c8d7',
    eLogAppGalleryLink: 'https://appgallery.huawei.com/#/app/C103005425',
    hubGuardAppGalleryLink: 'https://appgallery.huawei.com/#/app/C103303297',
    allUserReviewsLink: 'https://play.google.com/store/apps/details?id=com.tawitech.elog.main&showAllReviews=true',
    aboutAppLink: 'https://www.youtube.com/watch?v=C8f3oIaLB3g',
    privacyPolicyLink: 'https://elog-app.web.app/privacy-policy',

    maxQRCodeStrLength: 30,

    getBaseUrl: (currEnv) => {
        if (currEnv === 'dev') {
            return 'https://us-central1-elog-dev-app.cloudfunctions.net';
        } else if (currEnv === 'uat') {
            return 'https://us-central1-elog-uat-app.cloudfunctions.net';
        }

        return 'https://us-central1-elog-app.cloudfunctions.net';
    }
}